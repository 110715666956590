﻿// IMPORTS
@import "../globals/settings";

.home-tiles.tiles {
    background-color:$offwhite;
    padding: 2rem 0;
    @media #{$desktop} {
        display:none;
    }
    .tile {
        @include box-shadow(2px, 2px, 3px, 1px, rgba(212,212,212,1));
        margin: 1rem 0;
        background-color:#fff;
        display:flex;
        flex-direction:column;
        .tile-image {
            height:220px;
            @media #{$above1200} {
                height:160px;
            }
            @media #{$below1200} {
                height:130px;
            }
            @media #{$desktop} {
                height:150px;
            }
            margin: auto;
            padding:1rem;
            img { 
                @media #{$above575} {
                    @include vertical-align;
                }
            }
        }
        .tile-text {
            text-align:center; 
            background-color: #e3e3e3;
            padding: 1rem;
        }
    }
} 