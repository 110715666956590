*, *:before, *:after {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: white;
}

@media print {
  .container {
    width: auto;
  }
}
.home-tiles.tiles {
  background-color: #f7f7f7;
  padding: 2rem 0;
}
@media (max-width: 991.9999px) {
  .home-tiles.tiles {
    display: none;
  }
}
.home-tiles.tiles .tile {
  -webkit-box-shadow: 2px 2px 3px 1px rgb(212, 212, 212);
  -moz-box-shadow: 2px 2px 3px 1px rgb(212, 212, 212);
  box-shadow: 2px 2px 3px 1px rgb(212, 212, 212);
  margin: 1rem 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.home-tiles.tiles .tile .tile-image {
  height: 220px;
  margin: auto;
  padding: 1rem;
}
@media (min-width: 1200px) {
  .home-tiles.tiles .tile .tile-image {
    height: 160px;
  }
}
@media (max-width: 1200.9999px) {
  .home-tiles.tiles .tile .tile-image {
    height: 130px;
  }
}
@media (max-width: 991.9999px) {
  .home-tiles.tiles .tile .tile-image {
    height: 150px;
  }
}
@media (min-width: 576px) {
  .home-tiles.tiles .tile .tile-image img {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.home-tiles.tiles .tile .tile-text {
  text-align: center;
  background-color: #e3e3e3;
  padding: 1rem;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: white;
}

@media print {
  .container {
    width: auto;
  }
}
.tiles {
  background-color: #f7f7f7;
  padding: 2rem 0;
}
.tiles .tile {
  -webkit-box-shadow: 2px 2px 3px 1px rgb(212, 212, 212);
  -moz-box-shadow: 2px 2px 3px 1px rgb(212, 212, 212);
  box-shadow: 2px 2px 3px 1px rgb(212, 212, 212);
  margin: 1rem 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.tiles .tile .tile-image {
  margin: auto;
  padding: 1rem;
}
@media (min-width: 576px) {
  .tiles .tile .tile-image {
    height: 230px;
  }
}
@media (min-width: 1200px) {
  .tiles .tile .tile-image {
    height: 160px;
  }
}
@media (min-width: 576px) {
  .tiles .tile .tile-image img {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.tiles .tile .tile-text {
  text-align: center;
  background-color: #e3e3e3;
  padding: 1rem;
  height: 80px;
}
.tiles .tile .tile-text span {
  color: #454545;
  display: block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}