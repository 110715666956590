﻿// ----------------------------------------------------------------------
// VARIABLES, MIXINS & EXTENDS
// ----------------------------------------------------------------------

*, *:before, *:after {
    -moz-box-sizing: border-box; 
    -ms-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
}

// For iPhone X http://stephenradford.me/removing-the-white-bars-in-safari-on-iphone-x/
body {
    background-color:white;
}

// Safari bug with printing https://v4-alpha.getbootstrap.com/getting-started/browsers-devices/#printing
@media print {
  .container {
    width: auto;
  }
}

// Color Variables
$linkblue: #3366CC;
$lightblue: #f2f7fd;
$blue: #38648f;
$darkblue: #2c5277;
$darkerblue: #1a3d5f;
$navy:#003366;
$lightgrey: #e3e3e3; 
$grey: #b2b4b5;
$darkgrey: #9d9d9d;
$darkergrey: #767676;
$offwhite: #f7f7f7;
$greyblue: #567ba0;
$black: #454545;
$green: #006621;

// Media Query Variables
$desktoplarge: "(min-width: 1200px)";
$below1200: "(max-width: 1200.9999px)";
$above1200: "(min-width: 1200px)";
$below800: "(max-width: 800.9999px)";
$desktop: "(max-width: 991.9999px)";
$midrange: "(min-width: 992px) and (max-width: 1200.9999px)";
$tablettodesktop: "(min-width: 768px) and (max-width: 991.9999px)";
$tablet: "(max-width: 767.9999px)";
$above575: "(min-width: 576px)";
$below575: "(max-width: 575px)";
$phone: "(max-width: 480.9999px)";

// Mixins & Extends

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin box-shadow($top, $left, $blur, $size, $color) {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
}